<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="UsersIcon"
        size="25"
      />Reparti</h1>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
              <!-- <b-button
                variant="primary"
                class="text-nowrap"
                :to="{ name: 'apps-departments-add' }"
              >Nuovo Reparto</b-button> -->
              <b-button
                variant="primary"
                @click="exportCsv()"
              >
                Esporta CSV Divise
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDepartmentsListTable"
        class="position-relative"
        :items="fetchDepartments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Reparto corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'apps-departments-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <template #cell(sottoreparti)="row">
          <div class="text-center">
            <b-button
              v-if="!row.detailsShowing"
              pill
              size="sm"
              variant="primary"
              @click="
                row.toggleDetails()
              "
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="30"
              />
            </b-button>
            <b-button
              v-if="row.detailsShowing"
              variant="primary"
              size="sm"
              pill
              @click="row.toggleDetails"
            >
              <feather-icon
                icon="ChevronUpIcon"
                size="30"
              />
            </b-button>
          </div>
        </template>

        <template #cell(divise)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">
              <feather-icon
                :icon="getClothingIcon(data.item.clothing_enabled)"
                size="15"
                :class="getClothingColor(data.item.clothing_enabled)"
              />
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-departments-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item :to="{ name: 'apps-departments-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifica</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedDepartment = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
        <template #row-details="row">
          <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters">
              <div class="col position-relative">
                <div class="card shadow-none border-0 m-0 rounded-0">
                  <div class="card-body p-0">
                    <sub-departments-list :parent-department-id="row.item.id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDepartments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="delete-modal"
        title="Cancella Reparto"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Cancella Reparto
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler cancellare definitivamente questo Reparto e i suoi dati correlati?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteDepartment(selectedDepartment)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import useDepartmentsList from './useDepartmentsList'
import departmentsStoreModule from '../departmentsStoreModule'
import SubDepartmentsList from './SubDepartmentsList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,

    vSelect,
    SubDepartmentsList,
  },
  setup() {
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchDepartments,
      tableColumns,
      perPage,
      currentPage,
      totalDepartments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentsListTable,
      refetchData,
    } = useDepartmentsList()

    return {
      fetchDepartments,
      tableColumns,
      perPage,
      currentPage,
      totalDepartments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentsListTable,
      refetchData,

      // Filter
      avatarText,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 32,
        height: 32,
        class: 'm1',
      },
      selectedDepartment: null,
    }
  },
  methods: {
    exportCsv() {
      store
        .dispatch('app-departments/exportCsv', {})
        .then(response => {
          const now = moment().format('DD-MM-YYYY_HH-mm-ss')
          const blob = new Blob([response.data], {
            type: 'text/csv',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${now}_divise_aziendali_all.csv`
          link.click()
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            this.$router.replace({ name: 'auth-login' }).then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
    },
    getClothingIcon(clothingEnabled) {
      if (clothingEnabled) return 'CheckCircleIcon'
      return 'XCircleIcon'
    },
    getClothingColor(clothingEnabled) {
      if (clothingEnabled) return 'text-success'
      return 'text-danger'
    },
    deleteDepartment(id) {
      store
        .dispatch('app-departments/deleteDepartment', { id })
        .then(() => {
          this.selectedDepartment = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Reparto #${id} eliminato con successo`,
              icon: 'TruckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 422) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Errore Cancellazione Reparto',
                text: 'Per poter cancellare questo Reparto è necessario cancellare prima eventuali Utenti e Sottoreparti associati',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
