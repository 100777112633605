<template>
  <b-table
    ref="refSubDepartmentList"
    class="position-relative"
    :items="subDepartmentsList.fetchDepartments"
    responsive
    :fields="subDepartmentsList.tableColumns"
    primary-key="id"
    :sort-by.sync="subDepartmentsList.sortBy.value"
    show-empty
    empty-text="Nessun Sottoreparto corrispondente trovato"
    :sort-desc.sync="subDepartmentsList.isSortDirDesc.value"
  >
    <!-- Column: ID -->
    <template #cell(id)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
      </div>
    </template>

    <!-- Column: Name -->
    <template #cell(nome)="data">
      <b-media vertical-align="center">
        <b-link
          :to="{ name: 'apps-departments-view', params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.name }}
        </b-link>
      </b-media>
    </template>

    <template #cell(divise)="data">
      <div class="text-nowrap">
        <span class="text-capitalize">
          <feather-icon
            :icon="getClothingIcon(data.item.clothing_enabled)"
            size="15"
            :class="getClothingColor(data.item.clothing_enabled)"
          />
        </span>
      </div>
    </template>

    <!-- Column: Actions -->
    <template #cell(azioni)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item :to="{ name: 'apps-departments-view', params: { id: data.item.id } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Dettagli</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item :to="{ name: 'apps-departments-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Modifica</span>
                </b-dropdown-item>

                <b-dropdown-item
                @click="
                  $bvModal.show('delete-modal')
                  selectedDepartment = data.item.id
                "
                >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Cancella</span>
                </b-dropdown-item> -->
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BDropdown,
  BDropdownItem,
  BMedia,
  BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import departmentsStoreModule from '../departmentsStoreModule'
import useSubDepartments from './useSubDepartments'

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    BMedia,
    BLink,
  },

  props: ['parentDepartmentId'],
  setup(props) {
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const subDepartmentsList = useSubDepartments()

    subDepartmentsList.parentDepartmentId.value = props.parentDepartmentId

    return {
      subDepartmentsList,
      refSubDepartmentList: subDepartmentsList.refDepartmentsListTable,

      // Filter
      avatarText,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 32,
        height: 32,
        class: 'm1',
      },
      selectedDepartment: null,
    }
  },
  methods: {
    getClothingIcon(clothingEnabled) {
      if (clothingEnabled) return 'CheckCircleIcon'
      return 'XCircleIcon'
    },
    getClothingColor(clothingEnabled) {
      if (clothingEnabled) return 'text-success'
      return 'text-danger'
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
