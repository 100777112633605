import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useDepartmentsList() {
  const refDepartmentsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'sottoreparti', sortable: false },
    { key: 'divise', sortable: false },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'name',
  }
  const perPage = ref(10)
  const totalDepartments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDepartmentsListTable.value ? refDepartmentsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDepartments.value,
    }
  })

  const refetchData = () => {
    refDepartmentsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchDepartments = (ctx, callback) => {
    store
      .dispatch('app-departments/fetchDepartments', {
        root: true,
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
      })
      .then(response => {
        const departments = response.data[0]
        const total = response.data[1]

        callback(departments)
        totalDepartments.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchDepartments,
    tableColumns,
    perPage,
    currentPage,
    totalDepartments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDepartmentsListTable,

    refetchData,
  }
}
