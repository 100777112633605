<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="UsersIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Reparto</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          ID Reparto:
        </p>
        <h3 class="mb-1">
          #{{ departmentData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Nome Reparto:
        </p>
        <h3 class="mb-1">
          {{ departmentData.name }}
        </h3>
      </b-col>
      <b-col
        v-if="departmentData.parentDepartment"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Reparto di Appartenenza:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="UsersIcon"
            size="25"
            class="text-primary"
          />
          <b-link
            class="font-weight-bold text-nowrap"
            @click="reloadPage(departmentData.parentDepartment.id)"
          >
            #{{ departmentData.parentDepartment.id }} - {{ departmentData.parentDepartment.name }}
          </b-link>
        </h3>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          :variant="getColorByStatus(departmentData.clothing_enabled)"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="toggleClothing()"
        >
          <span>{{ getTextByStatus(departmentData.clothing_enabled) }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          :to="{ name: 'apps-departments-edit', params: { id: departmentData.id } }"
          variant="primary"
        >
          Modifica
        </b-button>
        <b-button
          variant="outline-danger"
          class="ml-1"
          @click="
            $bvModal.show('delete-modal')
            selectedDepartment = departmentData.id
          "
        >
          Elimina
        </b-button>
      </b-col>
    </b-row> -->
    <b-modal
      id="delete-modal"
      title="Cancella Reparto"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Reparto
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questo Reparto e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteDepartment(selectedDepartment)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BLink, BModal,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BModal,
  },
  props: {
    departmentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDepartment: null,
    }
  },
  methods: {
    getColorByStatus(active) {
      if (active) return 'danger'
      return 'success'
    },
    getTextByStatus(active) {
      if (active) return 'Disattiva Gestione Divise Aziendali'
      return 'Attiva Gestione Divise Aziendali'
    },
    toggleClothing() {
      const { id } = router.currentRoute.params
      let api = 'enableClothing'
      if (this.departmentData.clothing_enabled) api = 'disableClothing'
      store
        .dispatch(`app-departments/${api}`, { id })
        .then(() => {
          router.replace({ name: 'apps-departments-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Reparto #${id} modificato con successo`,
                icon: 'UsersIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    goBack() {
      this.$router.go(-1)
    },
    async reloadPage(parent_id) {
      this.$router.push('/')
      await new Promise(r => setTimeout(r, 100))
      this.$router.push(`/reparti/visualizza/${parent_id}`)
    },
    deleteDepartment(id) {
      store
        .dispatch('app-departments/deleteDepartment', { id })
        .then(() => {
          this.selectedDepartment = null
          this.$router.replace({ name: 'apps-departments-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Reparto #${id} eliminato con successo`,
                icon: 'UsersIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 422) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Errore Cancellazione Reparto',
                text: 'Per poter cancellare questo Reparto è necessario cancellare prima eventuali Utenti e Sottoreparti associati',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
