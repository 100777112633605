<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="pt-2">
          <b-col
            class="mb-4 d-flex justify-content-center align-items-center"
            cols="12"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              size="25"
              class="mr-2"
            />
            <h1>Divise Aziendali</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex justify-content-end align-items-center"
            cols="12"
          >
            <b-button
              variant="primary"
              @click="exportCsv()"
            >
              Esporta CSV
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDepartmentclothingsListTable"
        class="position-relative"
        :items="fetchDepartmentclothings"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Selezione Divisa trovata"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.display_name }}
          </b-link>
          <small class="text-muted">@{{ data.item.user.identifier }}</small>
        </template>
        <template #cell(scarpe)="data">
          <h5 class="font-weight-bold">
            {{ data.item.records[0].size.name }}
          </h5>
        </template>
        <template #cell(giacca)="data">
          <h5 class="font-weight-bold">
            {{ data.item.records[1].size.name }}
          </h5>
        </template>
        <template #cell(t-shirt)="data">
          <h5 class="font-weight-bold">
            {{ data.item.records[2].size.name }}
          </h5>
        </template>
        <template #cell(pantaloni)="data">
          <h5 class="font-weight-bold">
            {{ data.item.records[3].size.name }}
          </h5>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDepartmentclothings"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BLink,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useDepartmentclothingsList from './useDepartmentclothingsList'
import departmentclothingsStoreModule from '../departmentclothingsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BLink,
    BButton,
  },
  props: {
    canManage: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const DEPARTMENTCLOTHINGS_APP_STORE_MODULE_NAME = 'app-departmentclothings'

    // Register module
    if (!store.hasModule(DEPARTMENTCLOTHINGS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTCLOTHINGS_APP_STORE_MODULE_NAME, departmentclothingsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTCLOTHINGS_APP_STORE_MODULE_NAME)) { store.unregisterModule(DEPARTMENTCLOTHINGS_APP_STORE_MODULE_NAME) }
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchDepartmentclothings,
      tableColumns,
      perPage,
      currentPage,
      totalDepartmentclothings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentclothingsListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDepartmentUserRoleName,
    } = useDepartmentclothingsList()

    return {
      fetchDepartmentclothings,
      tableColumns,
      perPage,
      currentPage,
      totalDepartmentclothings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentclothingsListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDepartmentUserRoleName,

      // Filter
      avatarText,
      mediaUrl,
      selectedDepartmentuser: null,
      add_user_id: null,
      add_user_role_code: null,
      observer: null,
      limit: 10,
      search: '',
    }
  },
  methods: {
    exportCsv() {
      store
        .dispatch('app-departmentclothings/exportCsv', { department_id: this.$router.currentRoute.params.id })
        .then(response => {
          const now = moment().format('DD-MM-YYYY_HH-mm-ss')
          const blob = new Blob([response.data], {
            type: 'text/csv',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${now}_divise_aziendali_reparto_${this.$router.currentRoute.params.id}.csv`
          link.click()
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            this.$router.replace({ name: 'auth-login' }).then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
